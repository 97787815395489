import React from 'react'
import { strings } from 'js/strings'

export default function ({ page, showCategory = true }) {
  return (
    <section className='content-social-sharer'>
      <div className='content-social-sharer__container'>
        <Title page={page} showCategory={showCategory} />
        {/* <Social /> */}
        <hr className='content-social-sharer__break' />
      </div>
    </section>
  )
}

const Title = ({ page, showCategory }) => {
  const { title, date, categories } = page
  const firstCategory = categories.nodes[0]

  return (
    <>
      {showCategory && (
        <p className='content-social-sharer__pretitle content-pretitle-card__pretitle'>
          {firstCategory.name}
        </p>
      )}
      <h3 className='content-social-sharer__title content-pretitle-card__title'>
        {title}
      </h3>

      {(firstCategory.slug === 'blog-posts' ||
        firstCategory.slug === 'news-items') && (
        <p className='content-social-sharer__date'>{date}</p>
      )}
    </>
  )
}

const Social = () => {
  let thisUrl = ''
  if (typeof window !== 'undefined') {
    thisUrl = window.location.href
  }
  return (
    <ul className='content-social-sharer__list'>
      <li className='content-social-sharer__item'>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${thisUrl}`}
          className='content-social-sharer__link'
          target='_blank'
        >
          <svg id='sm-facebook-desktop' viewBox='0 0 20 20'>
            <path d='M20 10c0 5.5-4.5 10-10 10S0 15.5 0 10 4.5 0 10 0s10 4.5 10 10zm-9.2-3.4c0-.7.1-1.1 1-1.1h1.4l.1-2.4s-.7-.1-1.9-.1C8.9 3 7.9 4.5 7.9 6.1v2.3H6.2v2.5h1.7v6.2h2.9v-6.2h2.3l.2-2.5h-2.5V6.6z'></path>
          </svg>
        </a>
      </li>
      <li className='content-social-sharer__item'>
        <a
          href={`https://twitter.com/intent/tweet?text=${strings.checkOutThisArticle}&amp;url=${thisUrl}`}
          className='content-social-sharer__link'
          target='_blank'
        >
          <svg id='sm-twitter-desktop' viewBox='0 0 20 20'>
            <path d='M20 10c0 5.5-4.5 10-10 10S0 15.5 0 10 4.5 0 10 0s10 4.5 10 10zm-2.5-3.7c-.5.2-1.1.4-1.6.4.6-.4 1-.9 1.2-1.6-.6.3-1.1.6-1.8.7-.5-.5-1.2-.9-2.1-.9-1.6 0-2.8 1.3-2.8 2.9 0 .2 0 .4.1.6-2.4-.1-4.5-1.2-5.9-3-.2.4-.4.9-.4 1.4 0 1 .5 1.9 1.2 2.4-.5 0-.9-.1-1.3-.4 0 1.4 1 2.5 2.3 2.8-.2.1-.5.1-.7.1h-.5c.4 1.1 1.4 1.9 2.6 1.9-1 .8-2.2 1.3-3.5 1.3h-.7c1.2.8 2.8 1.2 4.3 1.2 5.2 0 8.1-4.3 8.1-8.1v-.4c.6-.2 1.1-.7 1.5-1.3z'></path>
          </svg>{' '}
        </a>
      </li>
      <li className='content-social-sharer__item'>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${thisUrl}`}
          className='content-social-sharer__link'
          target='_blank'
        >
          <svg id='sm-linkedin-desktop' viewBox='0 0 20 20'>
            <path d='M20 10c0 5.5-4.5 10-10 10S0 15.5 0 10 4.5 0 10 0s10 4.5 10 10zM7.5 5.2c0-.8-.6-1.5-1.7-1.5-1 0-1.7.6-1.7 1.5 0 .8.6 1.5 1.6 1.5 1.2 0 1.8-.7 1.8-1.5zm-.4 2.7H4.6V15h2.5V7.9zm9.1 3.3c0-2.3-1.1-3.8-2.9-3.8-1 0-1.8.6-2.1 1.5l-.1-1.1H8.6c0 .3.1 1.7.1 1.7v5.4h2.5v-3.8c0-1 .5-1.7 1.2-1.7.8 0 1.3.4 1.3 1.7v3.8h2.5v-3.7z'></path>
          </svg>{' '}
        </a>
      </li>
    </ul>
  )
}
